// import pages from './pages';
// import utilities from './utilities';
// import other from './other';

import { DashboardPages, DashboardMenuItems } from './dashboard';
import { OrdersPages, OrdersMenuItems } from './orders';
import { UsersPages, UsersMenuItems } from './users';
import { SettingsPages, SettingsMenuItems } from './settings';
import { ShiftsPages, ShiftsMenuItems } from './shifts';

// ==============================|| MENU ITEMS ||============================== //

export const pageItems = {
    items: [DashboardPages, OrdersPages,ShiftsMenuItems, UsersPages, SettingsPages ]
};

const menuItems = {
    items: [DashboardMenuItems, OrdersMenuItems, ShiftsMenuItems, UsersMenuItems, SettingsMenuItems ]
};

export default menuItems;