// Import Material-UI icons to replace the Tabler icons:
import ListIcon from "@mui/icons-material/List";
import AddIcon from "@mui/icons-material/Add";
import GroupIcon from "@mui/icons-material/Group";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

// Create the icons object with MUI icons:
const icons = {
  IconList: ListIcon,
  IconPlus: AddIcon,
  IconCloseShift: ReceiptLongIcon,
};
// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

export const ShiftsPages = {
  id: "shifts",
  title: "Зміни",
  // caption: 'Pages Caption',
  type: "group",
  children: [
    
    {
      id: "orders-list",
      title: "Список закритих змін",
      url: "/orders/list",
      type: "item",
    },
    {
      id: "shifts-close",
      title: "Закрити зміну",
      url: "/shifts/close",
      type: "item",
    },
    {
      id: "shifts-list",
      title: "Список закритих змін",
      // type: 'item',
      url: "/shifts/list",
      type: "collapse",
      children: [
        {
          id: "shift-page",
          title: "Зміна №",
          type: "item",
          pattern: "/orders/list/(\\d+)",
        },
      ],
    },
  ],
};

export const ShiftsMenuItems = {
  id: "shifts",
  title: "Зміни",
  // caption: 'Pages Caption',
  type: "group",
  children: [
    {
      id: "shifts-list",
      title: "Список закритих змін",
      type: "item",
      url: "/shifts/list",
      icon: icons.IconList,
    },
    {
      id: "shifts-close",
      title: "Закрити зміну",
      type: "item",
      url: "/shifts/close",
      icon: icons.IconCloseShift,
    },
  ],
};

export default ShiftsMenuItems;
