import React from "react";
import usePullToRefresh from "hooks/usePullToRefresh";

const PullToRefreshComponent = ({ onRefresh }) => {
  const { refreshing, pullStatus, pullAreaRef } = usePullToRefresh(onRefresh);

  const renderIcon = () => {
    switch (pullStatus) {
      case "pulling":
        return "⬇️"; // Arrow Down
      case "threshold":
        return "⬆️"; // Arrow Up
      case "refreshing":
        return "🔄"; // Loading Icon
      default:
        return "";
    }
  };

  return (
    <>
      <div
        ref={pullAreaRef}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
        //   background: "rgba(200, 200, 200, 0.8)", // Grey background
          height: "0px",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1000,
          transition: "height 0.1s ease-in-out",
        }}
      >
        <span style={{ fontSize: "24px" }}>{renderIcon()}</span>
      </div>
    </>
  );
};

export default PullToRefreshComponent;
