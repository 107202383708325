// Import Material-UI icons to replace the Tabler icons:
import ListIcon from "@mui/icons-material/List";
import AddIcon from "@mui/icons-material/Add";
import GroupIcon from "@mui/icons-material/Group";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

// Create the icons object with MUI icons:
const icons = {
  IconList: ListIcon,
  IconPlus: AddIcon,
  IconCloseShift: ReceiptLongIcon,
};
// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

export const OrdersPages = {
  id: "orders",
  title: "Замовлення",
  // caption: 'Pages Caption',
  type: "group",
  children: [
    // {
    //   id: "orders-close-shift",
    //   title: "Закрити зміну",
    //   url: "/orders/close-shift",
    //   type: "item",
    // },
    {
      id: "orders-list",
      title: "Список замовлень",
      url: "/orders/list",
      type: "item",
    },
    {
      id: "orders-add",
      title: "Додати нове",
      url: "/orders/add",
      type: "item",
    },
    {
      id: "orders-list",
      title: "Список замовлень",
      // type: 'item',
      url: "/orders/list",
      type: "collapse",
      children: [
        {
          id: "order-page",
          title: "Замовлення №",
          type: "item",
          pattern: "/orders/list/(\\d+)",
        },
      ],
    },
  ],
};

export const OrdersMenuItems = {
  id: "orders",
  title: "Замовлення",
  // caption: 'Pages Caption',
  type: "group",
  children: [
    {
      id: "orders-list",
      title: "Список замовлень",
      type: "item",
      url: "/orders/list",
      icon: icons.IconList,
    },

    {
      id: "orders-add",
      title: "Додати нове",
      type: "item",
      url: "/orders/add",
      icon: icons.IconPlus,
    },

    // {
    //   id: "orders-close-shift",
    //   title: "Закрити зміну",
    //   type: "item",
    //   url: "/orders/close-shift",
    //   icon: icons.IconCloseShift,
    // },
  ],
};

export default OrdersMenuItems;
