import { useState, useRef, useEffect } from "react";

/**
 * Custom hook to implement pull-to-refresh functionality.
 * @param {Function} onRefresh - Callback to execute when refreshing.
 */
const usePullToRefresh = (onRefresh) => {
  const [refreshing, setRefreshing] = useState(false);
  const [pullStatus, setPullStatus] = useState("idle"); // 'idle', 'pulling', 'threshold', 'refreshing'
  const startY = useRef(0);
  const pullDistance = useRef(0);
  const refreshThreshold = 100; // Minimum pull distance to trigger refresh
  const pullAreaRef = useRef(null);

  useEffect(() => {
    const handleTouchStart = (e) => {
      if (window.scrollY === 0) {
        startY.current = e.touches[0].clientY;
        pullDistance.current = 0; // Reset pull distance
        setPullStatus("idle");
      }
    };

    const handleTouchMove = (e) => {
      if (window.scrollY === 0) {
        // document.body.style.transition = "transform 0.1s ease-in-out";
        const currentY = e.touches[0].clientY;
        pullDistance.current = currentY - startY.current;

        if (pullDistance.current > 0) {
          e.preventDefault();
          
          document.body.style.transform = `translateY(${Math.min(
            pullDistance.current,
            refreshThreshold
          )}px)`;

          // Update pull status
          if (pullDistance.current >= refreshThreshold) {
            setPullStatus("threshold");
          } else {
            setPullStatus("pulling");
            // Dynamically adjust pull area height
            if (pullAreaRef.current) {
              pullAreaRef.current.style.top = `-${pullDistance.current}px`;
              pullAreaRef.current.style.height = `${pullDistance.current}px`;
            }
          }

        }
      }
    };

    const handleTouchEnd = () => {
      if (pullDistance.current >= refreshThreshold) {
        setRefreshing(true);
        setPullStatus("refreshing");
        document.body.style.transition = "transform 0.1s ease-in-out";
        onRefresh().finally(() => {
          setRefreshing(false);
          setPullStatus("idle");
          document.body.style.transform = "translateY(0)";
          if (pullAreaRef.current) {
            pullAreaRef.current.style.height = "0px";
          }
        });
      } else {
        document.body.style.transform = "translateY(0)";
        setPullStatus("idle");
        if (pullAreaRef.current) {
          pullAreaRef.current.style.height = "0px";
        }
      }

      // Reset pull distance
      pullDistance.current = 0;
    };

    // Attach event listeners
    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("touchmove", handleTouchMove, { passive: false });
    window.addEventListener("touchend", handleTouchEnd);

    // Cleanup event listeners
    return () => {
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleTouchEnd);
    };
  }, [onRefresh]);

  return { refreshing, pullStatus, pullAreaRef };
};

export default usePullToRefresh;
