import { useSelector } from "react-redux";

import { ThemeProvider } from "@mui/material/styles";
import { CircularProgress, CssBaseline, LinearProgress, StyledEngineProvider } from "@mui/material";

// routing
import Routes from "routes";

// defaultTheme
import themes from "themes";
import { useCallback } from "react";

// project imports
// import NavigationScroll from 'layout/NavigationScroll';
// import LinearProgress from '@mui/material/LinearProgress';
import usePullToRefresh from "hooks/usePullToRefresh";
import PullToRefreshComponent from "ui-component/pull-to-refresh";

// ==============================|| APP ||============================== //

const App = () => {
  // const customization = useSelector((state) => state.customization);
  // const loading = useSelector((state) => state.loading.status);

  const onRefresh = useCallback(() => {
    return new Promise((resolve) => {
      // resolve();
      setTimeout(() => {
        console.log("Data refreshed!");
        window.location.reload();
        resolve();
      }, 100);
    });
  }, []);


  // const refreshing = usePullToRefresh(onRefresh);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes({})}>
        <CssBaseline />
        <PullToRefreshComponent onRefresh={onRefresh}/>
        
        <Routes />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
